<template>
  <main>
    <OrderStep :step="1"/>
    <Spinner v-if="stateLoading"></Spinner>
    <div v-else>
      <div v-if="getMyCart.multiple" class="alert">
        <p>
          <font-awesome-icon :icon="['fa', 'triangle-exclamation']" size="xl"/>
          Vous vous apprêtez à passer une commande qui sera au départ de points de production différents,
          cela peut entraîner des surcoûts de transport!
        </p>
      </div>

      <div class="cart-container" v-if="getMyCartItems.length !== 0">
        <div class="main-col">
          <div class="table__wrapper">
            <table class="main-cart">
              <thead class="main-cart__header">
              <tr>
                <th class="col-item">Article</th>
                <th class="col-quantity">Quantité</th>
                <th class="col-price">Prix</th>
                <th class="col-delete"></th>
              </tr>
              </thead>
              <tbody class="main-cart__body" v-if="!getMyCart.multiple">
              <!--								<tr class="city">Départ de Saint-Coucou</tr>-->
              <tr v-for="(item, index) in getMyCartItems" class="item">
                <td class="item__meta">
                  <router-link :to="{name: 'Product', params: {id: item.product.product_tmpl}}">
                    <div class="wrapper">
                      <div class="item__meta__img">
                        <img v-lazy="item.product.image" :srcset="item.product.srcset"
                             :sizes="$store.state.sizes" :alt="`${item.product.name} photography`">
                      </div>
                      <div class="item__meta__text">
                        <div class="item__meta__name">
                          <h3>{{ item.product.name }}</h3>
                        </div>
                        <div class="item__meta__detail">
                          <p>{{ item.product.default_code }}</p>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </td>
                <td class="item__quantity">
                  <div class="wrapper">
                    <button aria-label="remove" type="button"
                            @click="removeUnitFromCart(item)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                           viewBox="0 0 24 24">
                        <path d="M5 11h14v2H5z"></path>
                      </svg>
                    </button>
                    <input aria-label="check" type="number" v-model="item.quantity" min="1"
                           @input="updateQuantity(index, $event)"
                           @blur="checkQuantity(index, $event)"/>
                    <button type="button" @click="addToCart(item)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                           viewBox="0 0 24 24">
                        <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z"></path>
                      </svg>
                    </button>
                  </div>
                </td>
                <td class="item__price">
                  {{ (item.price * item.quantity).toFixed(2) }}€
                </td>
                <td class="item__delete">
                  <button aria-label="remove" @click="removeFromCart(index)" class="button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                         viewBox="0 0 24 24"
                         style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;">
                      <path
                          d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path>
                      <path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
                    </svg>
                  </button>
                </td>
              </tr>
              </tbody>

              <tbody class="main-cart__body" v-else v-for="order in split">
              <tr class="city">Au Départ: {{ getDeparture(order) }}</tr>
              <tr v-for="(item, index) in getItems(order)" class="item">
                <td class="item__meta">
                  <router-link :to="{name: 'Product', params: {id: item.product.product_tmpl}}">
                    <div class="wrapper">
                      <div class="item__meta__img">
                        <img v-lazy="item.product.image" :srcset="item.product.srcset"
                             :sizes="$store.state.sizes" :alt="`${item.product.name} photography`">
                      </div>
                      <div class="item__meta__text">
                        <div class="item__meta__name">
                          <h3>{{ item.product.name }}</h3>
                        </div>
                        <div class="item__meta__detail">
                          <p>{{ item.product.default_code }}</p>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </td>
                <td class="item__quantity">
                  <div class="wrapper">
                    <button aria-label="remove" type="button"
                            @click="removeUnitFromCart(item)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                           viewBox="0 0 24 24">
                        <path d="M5 11h14v2H5z"></path>
                      </svg>
                    </button>
                    <input type="number" v-model="item.quantity" min="1"
                           @input="updateQuantity(index, $event)"
                           @blur="checkQuantity(index, $event)"/>
                    <button aria-label="add" type="button" @click="addToCart(item)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                           viewBox="0 0 24 24">
                        <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z"></path>
                      </svg>
                    </button>
                  </div>
                </td>
                <td class="item__price">
                  {{ (item.price * item.quantity).toFixed(2) }}€
                </td>
                <td class="item__delete">
                  <button aria-label="remove" type="button" @click="removeFromCart(index)" class="button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                         viewBox="0 0 24 24"
                         style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;">
                      <path
                          d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path>
                      <path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
                    </svg>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="table__wrapper__footer">
              <span class="line"></span>
              <router-link class="button" :to="{name: 'Shop', params: {category: 'huître-en-ligne'}}">Poursuivre mes
                achats
              </router-link>
            </div>
          </div>

          <router-link v-if="isAuthenticated" class="next-step button full" :to="{name:'Order'}">Suivant
          </router-link>
          <router-link v-else class="next-step dropdown-item button full"
                       :to="{name:'CartRegistration',params: {'fromPage': 'cart'}}">
            Commander
          </router-link>
        </div>
        <CartResume :step="1"/>
      </div>

      <div class="empty-cart" v-else>
        <h3>Mon panier est vide</h3>
        <router-link class="next-step dropdown-item button full"
                     :to="{name: 'Shop', params: {category: 'huître-en-ligne'}}">
          Poursuivre mes achats
        </router-link>
      </div>

      <!--      			<section>-->
      <!--      				<h2>Et si vous vous laissiez tenter par ... ?</h2>-->
      <!--      				<ProductSlider :products="related" />-->
      <!--      			</section>-->

    </div>
  </main>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import {getAPI} from "@/services/axios-api";
import OrderStep from "@/components/OrderStep";
import CartResume from "@/components/CartResume";
import Spinner from "@/components/Spinner";
import ProductSlider from "@/components/ProductSlider";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {library} from '@fortawesome/fontawesome-svg-core'
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons'

library.add(faEye, faEyeSlash)

export default {
  name: "Cart",
  data() {
    return {
      split: [],
      warehouse: [],
      related: []
    }
  },
  components: {'font-awesome-icon': FontAwesomeIcon, OrderStep, CartResume, Spinner, ProductSlider},
  computed: {
    ...mapGetters(['stateLoading']),
    ...mapGetters('cart', ['getMyCartItems', 'getMyCart', 'getShippingCost', 'isSplit', 'getTotalPrice']),
    ...mapGetters('pricelist', ['getPricelistItems']),
    ...mapState('auth', ['userId', 'isAuthenticated']),
    productIds: function () {
      let ids = '';
      this.getMyCartItems.forEach((value, i) => {
        if (i === this.getMyCartItems.length - 1) {
          ids += String(value.product.id)
        } else {
          ids += String(value.product.id) + ','
        }
      })
      return ids
    }
  },
  created() {
    this.getSplit();
  },
  methods: {
    ...mapActions('cart', ['removeFromCart']),
    ...mapActions('products', ['getRelatedProduct']),
    replace: function (event, str) {
      event.target.src = str.replace('https://odoo.lafamilleboutrais.group', 'http://51.178.140.145:8090')
    },
    getItems: function (i) {
      return this.getMyCartItems.filter(e => {
        return i.list_product.includes(e.product.id)
      })
    },
    getDeparture: function (i) {
      return this.warehouse.find(e => {
        return e.id === i.warehouse_id
      }).partner__city
    },
    getSplit: async function () {
      await this.$store.dispatch('changeStateLoading', true);
      await this.$store.dispatch('cart/getShippingCost');
      if (this.isSplit) {
        await getAPI.post('/shipping/split/', this.getMyCart)
            .then(res => {
              this.split = res.data.split;
              this.warehouse = res.data.warehouse;
              this.getRelatedProduct({'prices': this.getPricelistItems, 'productIds': this.productIds});
            })
      }
      await this.$store.dispatch('changeStateLoading', false);
    },
    addToCart: function (itemToAdd) {
      const item = {
        product: itemToAdd.product,
        quantity: 1,
        price_df: itemToAdd.price_df,
        price: itemToAdd.price,
        weight: itemToAdd.product.weight
      }
      this.$store.dispatch('cart/addToCart', item)
    },
    removeUnitFromCart: function (itemToRemove) {
      const item = {
        product: itemToRemove.product,
        quantity: 1,
        price_df: itemToRemove.price_df,
        price: itemToRemove.price,
        weight: itemToRemove.product.weight
      }
      this.$store.dispatch('cart/removeUnitFromCart', item)
    },
    checkQuantity: function (index, event) {
      // Update quantity to 1 if it is empty
      if (event.data === "") {
        let item = this.getMyCartItems[index];
        item.quantity = 1;
        const data = {
          'index': index,
          'item': item
        }
        this.$store.dispatch('cart/addFixQuantity', data);
      }
    },
    updateQuantity: function (index, event) {
      let item = this.getMyCartItems[index];
      let value = event.data;
      let valueInt = parseInt(value);
      if (value === "") {
        item.quantity = value;
      } else if (valueInt > 0 && valueInt < 100) {
        item.quantity = valueInt;
      }
      const data = {
        'index': index,
        'item': item
      }
      this.$store.dispatch('cart/addFixQuantity', data);
    },
  },
  watch: {
    'isSplit': {
      handler() {
        this.getSplit()
      },
      immediate: true,
    }
  },
}
</script>

<style lang="scss" scoped>
@use '@/scss/variables' as *;

.alert {
  width: 100%;
  max-width: 100%;
  margin: 20px 0;
}

.cart-container {
  @include flex(flex-start, flex-start, column, $gap: 20px);
  position: relative;
  width: 95vw;
  margin: 0 auto;
  @media screen and (max-width: 400px) {
    width: 90%;
  }
  @media screen and (min-width: 992px) {
    width: 90vw;
  }
  @media screen and (min-width: 1200px) {
    width: 80vw;
  }

  @media screen and (min-width: 900px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.main-col {
  @include flex(flex-start, flex-start, column, $gap: 30px);
  width: 100%;

  @media screen and (min-width: 899.99px) and (max-width: 991.99px) {
    width: 70vw;
  }

  @media screen and (min-width: 1200px) {
    flex: 0 0 calc(66% - 10px);
    max-width: calc(66% - 10px);
  }

  .next-step {
    margin-left: auto;
  }

}

.table__wrapper {
  @include flex(flex-start, flex-end, column, $gap: 20px);
  width: 100%;
  box-shadow: 0 6px 10px -3px rgba(0, 0, 0, 30%);
  border-radius: 10px;
  padding-bottom: 20px;

  &__footer {
    @include flex(space-between, center, $gap: 10px);
    margin: 0 20px;
    width: calc(100% - 40px);

    .line {
      flex: 1;
      display: block;
      height: .5px;
      width: 100%;
      background-color: $Brown;
    }

    a {
      width: fit-content;
    }
  }
}

:deep .main-cart {
  margin: 0 auto;
  border-spacing: 20px;
  vertical-align: middle;
  width: 100%;

  &__header {
    @media screen and (max-width: 577.99px) {
      display: none;
    }

    tr {
      position: relative;

      &:after {
        @include position($bottom: -2px, $left: 0);
        background-color: $Brown;
        height: 1px;
        width: 100%;
      }

      th {
        color: $Black;
        text-transform: uppercase;
        font-weight: 600;

        &.col-item {
          text-align: left;
        }

        &.col-quantity {
          padding: 0 10px;
        }

        &.col-price {
          padding: 0 10px;
        }

        &.col-delete {
        }
      }
    }
  }

  &__body {
    position: relative;

    &:not(:last-of-type):after {
      @include position($bottom: -10px, $left: 0);
      background-color: $Brown;
      height: 1px;
      width: 100%;
    }

    @media screen and (max-width: 577.99px) {
      @include flex(flex-start, flex-start, column, $gap: 30px);
      width: 100%;
    }

    tr.city {
      color: $Brown;
      font-size: 15px;
      font-style: italic;
      translate: 0 10px;
    }

    .item {
      @media screen and (max-width: 577.99px) {
        @include flex(flex-start, center, row, wrap, $gap: 10px);
        width: 100%;
      }

      &:not(:last-child) {
        position: relative;

        &:after {
          @include position($bottom: -10px, $left: 0);
          background-color: $Grey;
          height: .5px;
          width: 100%;

          @media screen and (max-width: 577.99px) {
            bottom: -20px;
          }
        }
      }

      &__meta {
        @media screen and (max-width: 577.99px) {
          order: 2;
          flex: 4;
        }

        a {
          text-decoration: none;
          transition: .3s;

          &:hover {
            opacity: .8;
          }
        }

        .wrapper {
          @include flex(flex-start, center, $gap: 10px);
        }

        &__img {
          @include flex(center, center);
          border-radius: 7.5px;
          overflow: hidden;
          max-height: 65px;
          max-width: 85px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &__text {
          @include flex(flex-start, flex-start, column);

          h3 {
            font-size: 15px;
            text-decoration: none;
            color: $Blue;
          }

          p {
            font-size: 13px;
            color: $Brown;
            font-style: italic;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }

      &__quantity {
        text-align: center;
        @media screen and (max-width: 577.99px) {
          flex: 0 0 100%;
          order: 4;
        }

        .wrapper {
          @include flex(center, center);
          @media screen and (max-width: 577.99px) {
            justify-content: space-between;
            width: fit-content;
            margin: 5px 10px 0;
            min-width: 65px; // + margin = image above
            max-width: 100%;
          }
        }

        input {
          appearance: none;
          width: 3ch;
          border: none;
          text-align: center;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          @include is-firefox() {
            -moz-appearance: textfield
          }
        }

        button {
          @include flex(center, center);
          appearance: none;
          border: solid 1px $Brown;
          background-color: $t;
          width: 20px;
          height: 20px;
          line-height: 1;
          transition: .2s;
          border-radius: 20px;

          svg path {
            fill: $Brown;
            transition: .2s;
          }

          &:hover, &:focus {
            background-color: $Brown;

            svg path {
              fill: $White;
            }
          }
        }
      }

      &__price {
        text-align: center;
        font-weight: 600;
        @media screen and (max-width: 577.99px) {
          order: 3;
          font-size: 20px;
          flex: 1;
        }
      }

      &__delete {
        text-align: right;
        @media screen and (max-width: 577.99px) {
          flex: 0 0 100%;
          order: 1;
        }

        button {
          margin-left: auto;
          border: none;
          padding: 3px 2px;

          svg {
            @include fixed-size(15px);

            path {
              fill: $Black;
            }
          }

          &:hover {
            background-color: $t;

            svg path {
              fill: $Red;
            }
          }
        }
      }
    }
  }
}

.empty-cart {
  @include flex(center, center, column, $gap: 20px);
  margin: 0 auto;
}

section {
  @include flex(flex-start, flex-start, column);
  width: 100%;

  & > h2 {
    color: $Brown;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    margin: 40px 0 20px;
    position: relative;
    width: calc(66% - 10px);
    overflow: hidden;

    //&::after {
    //	@include position(50%, $left: 100%);
    //	translate: -100%;
    //	background-color: $Grey;
    //	height: 1px;
    //	width: 100%;
    //}
  }


  .card {
    @include flex(flex-start, flex-start, column);
    color: #8BACD9;
    border-radius: 16px;
    border: solid 1px $Brown;
    overflow: hidden;
    box-shadow: 0 5px 15px -10px rgba(0, 0, 0, 1);
    transition: translate .5s;
    width: 20%;

    &:hover {
      transition: translate 1s, box-shadow .75s;
      translate: 0 -5px;
      box-shadow: 0 7.5px 15px -7.5px rgba(0, 0, 0, 1);

      .card_title {
        transition: .2s ease;
        color: $Brown;
      }

      .card_product_img img {
        transition: 1s ease;
        scale: 1.1;
      }

    }

    &_link {
      display: block;
      width: 100%;
      text-decoration: none;
    }

    &_product_img {
      max-height: 120px;
      overflow: hidden;
      width: 100%;
      flex: 0 0 66%;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        max-height: 200px;
        scale: 1;
        will-change: scale;
        transition: .3s;
      }
    }

    &_content {
      @include flex(flex-start, center, $gap: 5px);
      width: 100%;
      padding: .75rem;

      @media screen and (min-width: 576px) {
        padding: 5%;
      }
      @media screen and (min-width: 768px) {
        padding: 3% 5% 5%;
      }

      &_text {
        @include flex(flex-start, flex-start, column, $gap: .5rem);
        width: 100%;

        @media screen and (min-width: 576px) {
          gap: .25rem;
        }

        .card_title {
          text-transform: uppercase;
          text-align: left;
          font-weight: bold;
          font-size: 14px;
          letter-spacing: .5px;
          color: $Black;

          @media screen and (min-width: 992px) {
            font-size: 1rem;
          }
        }

        .card_category {
          font-size: 12px;
          font-weight: lighter;
          text-align: left;
          color: $Grey;

          @media screen and (min-width: 768px) {
            font-size: 14px;
          }
        }

        .card_price {
          color: $Blue;
          font-weight: 700;
        }
      }
    }

    .addcart {
      border-radius: 50px;
      padding: 5px;

      svg {
        @include fixed-size(20px)
      }
    }
  }
}

</style>